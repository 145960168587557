//
//
//
//
//
//
//
//
//
//
//
//

import AppHeader from '~/components/layout/Header'
import AppIntro from '~/components/Intro'
import AppFooter from '~/components/layout/Footer'
export default {
  components: { AppFooter, AppIntro, AppHeader },
  props: {
    error: {
      type: Object,
      require: true,
      default: () => {}
    }
  }
}
