export const linksNav = [
  {
    name: {
      ru: 'Главная',
      en: 'Home'
    },
    path: '/',
    exact: true
  },
  {
    name: {
      ru: 'О нас',
      en: 'About us'
    },
    path: '/about-us',
    exact: true
  },
  {
    name: {
      ru: 'Номера',
      en: 'Rooms'
    },
    path: '/rooms',
    exact: false
  },
  {
    name: {
      ru: 'Услуги',
      en: 'Services'
    },
    path: '/services',
    exact: true
  },
  {
    name: {
      ru: 'FAQ',
      en: 'FAQ'
    },
    path: '/faq',
    exact: true
  },
  {
    name: {
      ru: 'Контакты',
      en: 'Contacts'
    },
    path: '/contacts',
    exact: true
  },
  {
    name: {
      ru: 'Новости',
      en: 'News'
    },
    path: '/news',
    exact: true
  },
  {
    name: {
      en: 'Visa support',
      ru: 'Визовая поддержка'
    },
    path: '/visa-support',
    exact: true,
    footerOnly: true
  }
]
