//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'
import { linksNav } from '~/content/links'

export default {
  name: 'AppHeaderNav',
  computed: {
    ...mapState('data', ['$tt']),
    links () {
      return linksNav.filter(link => !link.footerOnly)
    },
    actualLocale () {
      return this.$i18n.locale
    },
    booking () {
      return this.$tt('common.booking')
    }
  },
  methods: {
    changeLanguage (lang) {
      this.$i18n.setLocale(lang)
    },
    ...mapActions('layout', ['toggleBookingModal'])
  }
}
