//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'
import { introBackgrounds } from '@/content/introBackgrounds'

export default {
  name: 'AppIntro',
  data () {
    return {
      currentPageTitle: '',
      introBackgrounds: ''
    }
  },
  computed: {
    ...mapState('data', ['$tt', 'roomsData', 'news']),
    getRouterParam () {
      return this.$route.params.id
    },
    getRoomName () {
      const param = this.getRouterParam

      const slugs = this.roomsData?.map(room => room.slug) ?? []

      if (!slugs.includes(param)) {
        return {
          title: 'An error has occurred',
          image: 'intro--error-page'
        }
      }
      return {
        title: this.$tt(`roomsTitles.${param}`),
        image: `intro--${param}`
      }
    },
    getNewsName () {
      const param = this.getRouterParam

      const news = this.news?.find(news => news.slug === param)

      if (!news) {
        return {
          title: 'An error has occurred',
          image: 'intro--error-page'
        }
      }

      return {
        title: news.title[this.$i18n.locale],
        image: `intro--${param}`
      }
    },

    getPageName () {
      let routeName = ''
      try {
        routeName = this.$route.name.slice(0, -5)
      } catch (e) {
        return 'An error has occurred'
      }
      switch (routeName) {
        case 'index':
          return this.$tt('introTitle.index')
        case 'about-us':
          return this.$tt('introTitle.aboutUs')
        case 'rooms':
          return this.$tt('introTitle.rooms')
        case 'services':
          return this.$tt('introTitle.services')
        case 'faq':
          return this.$tt('introTitle.faq')
        case 'contacts':
          return this.$tt('introTitle.contacts')
        case 'news':
          return this.$tt('introTitle.news')
        case 'visa-support':
          return this.$tt('introTitle.visaSupport')
        case 'rooms-id':
          return this.getRoomName.title
        case 'news-id':
          return this.getNewsName.title
        default:
          return routeName
      }
    },
    getIntroClass () {
      let routeName = ''
      try {
        routeName = this.$route.name.slice(0, -5)
      } catch (e) {
        return 'intro--error-page'
      }
      switch (routeName) {
        case 'index':
          return 'intro--main'
        case 'about-us':
          return 'intro--about'
        case 'rooms':
          return 'intro--rooms'
        case 'services':
          return 'intro--services'
        case 'faq':
          return 'intro--faq'
        case 'contacts':
          return 'intro--contacts '
        case 'news':
          return 'intro--news '
        case 'rooms-id':
          return this.getRoomName.image
        case 'news-id':
          return this.getNewsName.image
        default:
          return 'intro--error-page'
      }
    },
    getBgImage () {
      const param = this.getRouterParam

      if (param) {
        const room = this.roomsData?.find(room => room.slug === param)

        if (room?.background?.jpg) {
          return room.background.jpg
        }

        const news = this.news?.find(news => news.slug === param)

        if (news?.image?.jpg) {
          return news.image.jpg
        }
      }

      const route = this.$route.path
        .slice()
        .replace('en', '')
        .replace('//', '/')
      return introBackgrounds[route]?.jpg ?? ''
    }
  },
  methods: {
    ...mapActions('layout', ['toggleBookingModal'])
  }
}
