//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'

export default {
  name: 'BookingModal',
  computed: {
    ...mapState('layout', ['isBookingModalOpen']),
    ...mapState('data', ['$tt']),
    translate () {
      return this.$tt('common')
    },
    getLocale () {
      return this.$i18n.locale || 'en'
    }
  },
  methods: { ...mapActions('layout', ['toggleBookingModal']) }
}
