export const introBackgrounds = {
  '/': {
    jpg: require('@/assets/images/bg/tmp/bg6.jpg?resize'),
    webp: require('@/assets/images/bg/tmp/bg6.jpg?resize&format=webp')
    // avif: require('@/assets/images/bg/bg3.jpg?resize&format=avif')
  },
  '/about-us': {
    jpg: require('@/assets/images/bg/tmp/bg5.jpg?resize'),
    webp: require('@/assets/images/bg/tmp/bg5.jpg?resize&format=webp')
    // // avif: require('@/assets/images/bg/bg6.jpg?resize&format=avif')
  },
  '/rooms': {
    jpg: require('@/assets/images/bg/bg5.jpg?resize'),
    webp: require('@/assets/images/bg/bg5.jpg?resize&format=webp')
    // // avif: require('@/assets/images/bg/bg5.jpg?resize&format=avif')
  },
  '/services': {
    jpg: require('@/assets/images/bg/bg8.jpg?resize'),
    webp: require('@/assets/images/bg/bg8.jpg?resize&format=webp')
    // // avif: require('@/assets/images/bg/bg8.jpg?resize&format=avif')
  },
  '/faq': {
    jpg: require('@/assets/images/bg/tmp/bg3.jpg?resize'),
    webp: require('@/assets/images/bg/tmp/bg3.jpg?resize&format=webp')
    // // avif: require('@/assets/images/bg/bg10.jpg?resize&format=avif')
  },
  '/contacts': {
    jpg: require('@/assets/images/bg/bg1.jpg?resize'),
    webp: require('@/assets/images/bg/bg1.jpg?resize&format=webp')
    // // avif: require('@/assets/images/bg/bg1.jpg?resize&format=avif')
  },
  '/news': {
    jpg: require('@/assets/images/bg/bg7.jpg?resize'),
    webp: require('@/assets/images/bg/bg7.jpg?resize&format=webp')
  },
  '/visa-support': {
    jpg: require('@/assets/images/bg/bg8.jpg?resize'),
    webp: require('@/assets/images/bg/bg8.jpg?resize&format=webp')
  }
}
