//
//
//
//
//
//
//

export default {
  name: 'AppMobileLangMenu',
  methods: {
    changeLanguage (lang) {
      this.$i18n.setLocale(lang)
    }
  }
}
