//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapState } from 'vuex'
import AppLogo from '~/components/Logo'
import { linksNav } from '~/content/links'

export default {
  name: 'AppFooter',
  data () {
    return {
      links2: [
        {
          id: 'privacyPolicyHostelPalmburg',
          ru: 'Политика конфиденциальности Хостел Пальмбург',
          en: 'Privacy Policy Hostel Palmburg'
        },
        {
          id: 'oferta',
          ru: 'Договор публичной оферты',
          en: 'Public offer agreement'
        },
        {
          id: 'userAgreementPalmburg',
          ru: 'Пользовательское соглашение Пальмбург',
          en: 'User Agreement Hostel Palmburg'
        },
        {
          id: 'rulesForStayingAtHostelPalmburg',
          ru: 'Правила проживания в Хостеле Пальмбург',
          en: 'Rules for staying at Hostel Palmburg'
        },
        {
          id: 'consumerCornerHostelPalmburg',
          ru: 'Уголок потребителя Хостел Пальмбург',
          en: 'Consumer Corner Hostel Palmburg'
        }
      ]
    }
  },
  components: {
    AppLogo
  },
  computed: {
    ...mapState('data', ['$tt']),
    links () {
      return linksNav
    },
    translate () {
      return {
        footer: this.$tt('footer'),
        common: this.$tt('common')
      }
    },
    actualLocale () {
      return this.$i18n.locale
    }
  },
  methods: { ...mapActions('layout', ['toggleBookingModal']) }
}
