import { render, staticRenderFns } from "./default.vue?vue&type=template&id=6fcf02f8&lang=pug&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BookingModal: require('/Users/petrkopev/Downloads/hostel-palmburg/components/BookingModal.vue').default})
