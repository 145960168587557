//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { linksNav } from '~/content/links'
import AppLogo from '~/components/Logo'

export default {
  name: 'AppMobileMenu',
  components: { AppLogo },
  data () {
    return {
      isMenuActive: false
    }
  },
  computed: {
    links () {
      return linksNav
    },
    getCssMobile () {
      return {
        'mobile-menu': !this.isMenuActive,
        'mobile-menu--active': this.isMenuActive
      }
    },
    actualLocale () {
      return this.$i18n.locale
    }
  }
}
